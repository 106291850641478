import { ExternalLinkIcon } from '@heroicons/react/solid';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { calculateBountyReward } from '../../utils/calculateBountyReward';
import formatNumber from '../../utils/formatNumber';
import { getAsset } from '../../utils/getAsset';
import { getImageUrl } from '../../utils/getIpfsImageUrl';
import parseCID from '../../utils/parseCID';
import Button from '../Button';

dayjs.extend(relativeTime);

const FloorBounty = ({ bounty }) => {
  const { data: info } = useQuery({
    queryKey: ['listing', bounty.assetId],
    enabled: !bounty.image && bounty.collection === 'Shitty Cities',
    queryFn: async () => {
      const asset = await getAsset(bounty.assetId);
      const image = await getImageUrl(asset);
      return { name: asset.params.name, image };
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
  const theName = bounty.name || info?.name;
  const theImage = bounty.image || info?.image;

  const bountyFromNow = dayjs(bounty.createdAt).fromNow(true);

  return (
    <div className='group relative mx-4 md:mx-0 min-w-[250px] md:min-w-0 max-w-[250px] md:max-w-none'>
      <div className='relative overflow-hidden group shadow-md bg-neutral-900 flex flex-col rounded-lg transition duration-300'>
        <a href={bounty.link} target='_blank' className='block text-[0px] aspect-square relative'>
          <>
            {theImage && (
              <LazyLoadImage src={theImage} alt={bounty.name} width={400} height={400} className='absolute inset-0 object-cover w-full h-full' />
            )}
          </>
        </a>

        <div className={clsx('bg-gray-900 group-hover:translate-y-[-50px] transition duration-200 flex flex-col gap-1 p-3')}>
          <a href={bounty.link} target='_blank' className='flex flex-col justify-between'>
            <div>
              <p className='text-neutral-400 text-sm'>{bounty.collection}</p>
              <h2 className='font-bold flex flex-col text-neutral-50'>
                <span>{theName}</span>
                <span className='text-sm text-neutral-400 font-normal'>
                  <span>{bounty.assetId}</span>
                </span>
              </h2>
            </div>
          </a>

          <div className='flex flex-col gap-1'>
            <p className='flex flex-col md:flex-row justify-between'>
              <span className='text-neutral-400'>On floor for</span> <span className='font-bold'>{bountyFromNow}</span>
            </p>
            <p className='flex flex-col md:flex-row justify-between'>
              <span className='text-neutral-400'>Price</span>{' '}
              <span className='font-bold inline-flex gap-2'>
                <span className='inline-flex items-center justify-center w-5 h-5 p-1 bg-primary-500 rounded-full'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113 113.4' className='w-full h-full'>
                    <polygon points='19.6 113.4 36 85 52.4 56.7 68.7 28.3 71.4 23.8 72.6 28.3 77.6 47 72 56.7 55.6 85 39.3 113.4 58.9 113.4 75.3 85 83.8 70.3 87.8 85 95.4 113.4 113 113.4 105.4 85 97.8 56.7 95.8 49.4 108 28.3 90.2 28.3 89.6 26.2 83.4 3 82.6 0 65.5 0 65.1 0.6 49.1 28.3 32.7 56.7 16.4 85 0 113.4 19.6 113.4'></polygon>
                  </svg>
                </span>
                <span>{formatNumber(bounty.price)}</span>
              </span>
            </p>
            <p className='flex flex-col md:flex-row justify-between'>
              <span className='text-neutral-400'>Bounty Reward</span>{' '}
              <span className='font-bold inline-flex items-center'>
                <img src='/shitty-treats-transparent.png' className='inline w-[25px]' />
                {formatNumber(calculateBountyReward(bounty))}
              </span>
            </p>
          </div>

          <div className='absolute bottom-[-50px] left-0 right-0 group-hover:opacity-100 opacity-0 duration-200 transition p-3'>
            <Button href={bounty.link} target='_blank' className='w-full text-center flex items-center justify-center gap-1 text-sm'>
              {bounty.origin === 'ALGOxNFT'
                ? 'ALGOxNFT'
                : bounty.origin === 'EXA Market'
                ? 'EXA Market'
                : bounty.origin === 'rand' || bounty.origin === 'Rand Gallery'
                ? 'Rand Gallery'
                : 'Unknown'}
              <div className='w-5'>
                <ExternalLinkIcon />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorBounty;
