import Link from 'next/link';
import React from 'react';
import Spinner from './Spinner';
import clsx from 'clsx';

function Button({ children, onClick, href, link, target, theme = 'primary', className, loading, disabled, type = 'button' }) {
  const defaultClasses =
    'px-4 whitespace-nowrap py-1 md:px-12 md:py-2 font-bold transition duration-150 border-2 text-center inline-flex justify-center items-center rounded-md';

  const themes = {
    primary: 'border-transparent text-neutral-900 hover:opacity-75 bg-primary-500',
    outline: 'hover:bg-primary-500 hover:text-neutral-800 border-primary-500 text-primary-500',
    'outline-gray': 'hover:bg-gray-700 hover:text-gray-100 border-gray-400 text-gray-400',
    'outline-gray-dark': 'hover:border-gray-700 hover:text-gray-100 border-gray-900 bg-gray-900 text-gray-400',
    discord: 'border-transparent text-neutral-50 hover:opacity-75 bg-discord-500',
    none: 'border-transparent !p-0',
  };
  const themeClasses = themes[theme];

  const classes = clsx(defaultClasses, themeClasses, className, disabled && 'opacity-50 cursor-not-allowed');

  if (link) {
    return (
      <Link href={href} target={target}>
        <a className={classes}>{children}</a>
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} target={target} className={classes}>
        {children}
      </a>
    );
  }

  return (
    <button onClick={onClick} className={classes} disabled={disabled || loading} type={type}>
      {loading ? <Spinner dimensionClasses='w-5 h-5' /> : <>{children}</>}
    </button>
  );
}

export default Button;
